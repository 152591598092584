<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>HeySens</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
			selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true
		}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top align-items-center">
			<li>
				<span>{{ userName }}</span>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="logout">
					<i class="pi pi-sign-out"></i>
					<span>Settings</span>
				</button>
			</li>
		</ul>
		<ConfirmPopup></ConfirmPopup>
	</div>
</template>

<script>
import { removeToken } from '@/utils/auth'

export default {
	data() {
		return {
			userName: null,
		}
	},
	mounted() {
		this.localUserInfo = JSON.parse(window.localStorage.getItem('userinfo'));
		this.userName = this.localUserInfo.username;
    },
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		topbarImage() {
			return this.$appState.darkTheme ? '/images/heysens-logo.svg' : '/images/heysens-logo.svg';
		},
		logout() {
			removeToken();
			window.localStorage.clear()
			location.reload()
			this.$http('/api/admin/login/exit', {
				user: JSON.parse(window.localStorage.getItem('userinfo')).phone
			}).then((res) => {
				if (res.code == 200) {
					removeToken()
					window.localStorage.clear()
					location.reload()
				}
			})
			.catch((err) => { console.log(err) });
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>