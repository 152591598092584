import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
    path: '/',
    name: 'app',
    component: App,
    children: [
        {
            path: '',
            name: 'dashboard',
            component: () => import('./components/Dashboard.vue')
        },
        {
            path: '/charttable',
            name: 'charttable',
            component: () => import('./components/ChartTable.vue')
        },
        {
            path: '/reportdownload',
            name: 'reportdownload',
            component: () => import('./components/ReportDownload.vue')
        },
        //Start heysens
        {
            path: '/groupconfig',
            name: 'groupconfig',
            component: () => import('./components/GroupConfig.vue'),
            children: [
                {
                    path: '/group/cluster',
                    component: () => import('./components/group/ClusterConfig.vue')
                },
                {
                    path: '/group/field',
                    component: () => import('./components/group/FieldConfig.vue')
                },
                {
                    path: '/group/area',
                    component: () => import('./components/group/AreaConfig.vue')
                }
            ],
        },
        {
            path: '/notifymanage',
            component: () => import('./components/NotifyManage.vue')
        },
        {
            path: '/notifylist',
            name: 'notifylist',
            component: () => import('./components/NotifyList.vue')
        },
        {
            path: '/departmentmanage',
            name: 'departmentmanage',
            component: () => import('./components/DepartmentManage.vue')
        },
        {
            path: '/employeeconfig',
            name: 'employeeconfig',
            component: () => import('./components/EmployeeConfig.vue')
        },
        {
            path: '/eventrecords',
            name: 'eventrecords',
            component: () => import('./components/EventRecords.vue')
        },
        {
            path: '/eventstrategy',
            name: 'eventstrategy',
            component: () => import('./components/EventStrategy.vue')
        },
        {
            path: '/targetregist',
            name: 'targetregist',
            component: () => import('./components/TargetRegist.vue')
        },
        {
            path: '/sensormanage',
            name: 'sensormanage',
            component: () => import('./components/SensorManage.vue')
        },
        {
            path: '/rolemanage',
            name: 'rolemanage',
            component: () => import('./components/RoleManage.vue')
        },
        {
            path: '/safermanage',
            name: 'safermanage',
            component: () => import('./components/SaferManage.vue')
        },
        {
            path: '/showerdevicemanage',
            name: 'showerdevicemanage',
            component: () => import('./components/ShowerDeviceManage.vue')
        },
        {
            path: '/showerrecords',
            name: 'showerrecords',
            component: () => import('./components/ShowerRecords.vue')
        },
        {
            path: '/showerstaffmanage',
            name: 'showerstaffmanage',
            component: () => import('./components/ShowerStaffManage.vue')
        },
        {
            path: '/document',
            name: 'document',
            component: () => import('./components/Document.vue'),
            //component: () => import('./components/MenuDemoBread.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./components/menu/PersonalDemo.vue')
                },
                {
                    path: '/menu/seat',
                    component: () => import('./components/menu/SeatDemo.vue')
                },
                {
                    path: '/menu/payment',
                    component: () => import('./components/menu/PaymentDemo.vue')
                },
                {
                    path: '/menu/confirmation',
                    component: () => import('./components/menu/ConfirmationDemo.vue')
                },
            ]
        },
        {
            path: '/remotehelp',
            name: 'remotehelp',
            component: () => import('./components/RemoteHelp.vue')
        },
        {
            path: '/logs',
            name: 'logs',
            component: () => import('./components/LogManage.vue')
        },
        //End heysens
        {
            path: '/floatlabel',
            name: 'floatlabel',
            component: () => import('./components/FloatLabelDemo.vue')
        },
        {
            path: '/invalidstate',
            name: 'invalidstate',
            component: () => import('./components/InvalidStateDemo.vue')
        },
        {
            path: '/button',
            name: 'button',
            component: () => import('./components/ButtonDemo.vue')
        },
        {
            path: '/table',
            name: 'table',
            component: () => import('./components/TableDemo.vue')
        },
        {
            path: '/list',
            name: 'list',
            component: () => import('./components/ListDemo.vue')
        },
        {
            path: '/tree',
            name: 'tree',
            component: () => import('./components/TreeDemo.vue')
        },
        {
            path: '/panel',
            name: 'panel',
            component: () => import('./components/PanelsDemo.vue')
        },
        {
            path: '/overlay',
            name: 'overlay',
            component: () => import('./components/OverlayDemo.vue')
        },
        {
            path: '/media',
            name: 'media',
            component: () => import('./components/MediaDemo.vue')
        },
        {
            path: '/menu',
            component: () => import('./components/MenuDemo.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./components/menu/PersonalDemo.vue')
                },
                {
                    path: '/menu/seat',
                    component: () => import('./components/menu/SeatDemo.vue')
                },
                {
                    path: '/menu/payment',
                    component: () => import('./components/menu/PaymentDemo.vue')
                },
                {
                    path: '/menu/confirmation',
                    component: () => import('./components/menu/ConfirmationDemo.vue')
                },
            ],
        },
        {
            path: '/messages',
            name: 'messages',
            component: () => import('./components/MessagesDemo.vue')
        },
        {
            path: '/file',
            name: 'file',
            component: () => import('./components/FileDemo.vue')
        },
        {
            path: '/chart',
            name: 'chart',
            component: () => import('./components/ChartDemo.vue')
        },
        {
            path: '/misc',
            name: 'misc',
            component: () => import('./components/MiscDemo.vue')
        },
        {
            path: '/empty',
            name: 'empty',
            component: () => import('./components/EmptyPage.vue')
        },
        {
            path: '/documentation',
            name: 'documentation',
            component: () => import('./components/Documentation.vue')
        },
        {
            path: '/blocks',
            name: 'blocks',
            component: () => import('./components/BlocksDemo.vue')
        },
        {
            path: '/icons',
            name: 'icons',
            component: () => import('./components/IconsDemo.vue')
        }
    ]
    },
    {
        path: '/login',
            name: 'login',
                component: () => import('./pages/Login.vue')
    },
    {
        path: '/landing',
            name: 'landing',
                component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
            name: 'error',
                component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
            name: 'notfound',
                component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
            name: 'access',
                component: () => import('./pages/Access.vue')
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue')
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
    {
        path: '/chartdemo',
        name: 'chartdemo',
        component: () => import('./pages/ChartDemo.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
