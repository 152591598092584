import {service, baseURL} from '@/utils/request'
import { getToken } from '@/utils/auth'

export function login(data) {
	return service({
		url: '/api/admin/login/login',
		method: 'post',
		data
	})
}

// export function getInfo(token) {
//   return request({
//     url: '/vue-admin-template/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

export function logout() {
	return service({
		url: '/api/index/login/exit',
		method: 'post',
		headers: {
			indextoken: JSON.parse(getToken()).token
		}
	})
}
