import {service, baseURL} from '@/utils/request'
//import axios from 'axios';

export function _http(url, data, method = 'POST') {
	return service({
		url,
		method,
		data
	})
}

// export function _download(url, data, method='POST'){
// 	return axios.get(
// 		url, 
// 		params: {},
		
// 	)
// }

export const errorMessage = (msg, that) => {
	that.$toast.add({ severity: 'error', summary: '请求错误', detail: msg, life: 2000 });
}

export const hostUrl = baseURL;
