import { login, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter, constantRoutes } from '@/router.js'

const state = {
	token: getToken(),
	name: '',
	avatar: '',
	routes: [],
	addRoutes: []
}
const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, state)
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles
		state.routes = []
	},
	SET_ROUTES: (state, routes) => {
		state.addRoutes = routes
		state.routes = constantRoutes.concat(routes)
	}

}

const actions = {
	// user login
	login({ commit }, userInfo) {
		//console.log(userInfo)
		const { username, password, us, enterprise_pid } = userInfo
		return new Promise((resolve, reject) => {
			login({ username: username.trim(), password: password, enterprise_pid: enterprise_pid, us: us }).then(response => {
				const { data } = response
				//console.log(data.length, data)
				if (data.length !== 0) {
					//console.log('---')
					// 通过的话存一些缓存，存token，存权限列表
					setToken(data.token)
					//console.log("userInfo")
					window.localStorage.setItem('userinfo', JSON.stringify(data))
					window.localStorage.setItem('juri', JSON.stringify(data.juri_info))

					//console.log(data)
					// setToken(data)
					commit('SET_TOKEN', data.token)
					resolve()
				} else {
					this.$message.error('请输入正确的账号密码')
				}

			}).catch(error => {
				// this.$message.error('请输入正确的账号密码')
				reject(error)
			})
		})
	},

	// get user info
	// getInfo({ commit, state }) {
	// 	return new Promise((resolve, reject) => {
	// 		getInfo(state.token).then(response => {
	// 			const { data } = response
	// 			console.log(data)
	// 			if (!data) {
	// 				return reject('Verification failed, please Login again.')
	// 			}

	// 			const { name, avatar } = data
	// 			commit('SET_ROLES', role)
	// 			commit('SET_NAME', name)
	// 			commit('SET_AVATAR', avatar)
	// 			resolve(data)
	// 		}).catch(error => {
	// 			reject(error)
	// 		})
	// 	})
	// },

	// user logout
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				removeToken() // must remove  token  first
				resetRouter()
				commit('RESET_STATE')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({ commit }) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '')
			commit('SET_ROLES', [])
			removeToken()
			resolve()
		})
	},

	// // dynamically modify permissions
	// async changeRoles({ commit, dispatch }, role) {
	// 	const token = role + '-token'
	// 	commit('SET_TOKEN', token)
	// 	setToken(token)

	// 	const { roles } = await dispatch('getInfo')

	// 	resetRouter()

	// 	// generate accessible routes map based on roles
	// 	const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
	// 	// dynamically add accessible routes
	// 	router.addRoutes(accessRoutes)

	// 	// reset visited views and cached views
	// 	dispatch('tagsView/delAllViews', null, { root: true })
	// },
	generateRoutes({ commit }, routers) {
		return new Promise(resolve => {
			commit('SET_ROUTES', routers)
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}

